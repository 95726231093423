 <!--
  程序版本：V3.0
  版权所有：深圳市科飞时速网络科技有限公司
  技术支持：info@21gmail.com
  单元名称：测试界面(开发时用)
  开始时间：2021-1-20
  开发人员：刘巍骏
  最后修改：2021-1-20
  备注说明：如需修改请联系开发人员
  -->
<template>
  <div>
    <el-table
          :data="tableData"
          style="width: 50%"
          border>
          <el-table-column
            prop="date"
            label="日期"
            width="180"
            align="center">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="180"
            align="center">
          </el-table-column>
          <el-table-column
            prop="address"
            label="地址"
            align="center">
          </el-table-column>
      <el-table-column
      align="center"
      v-for="(item,index) in arr" width="150">
      <template slot="header" slot-scope="scope">
        <span @click="dialogVisible = true">{{item.name}}<i class="el-icon-circle-plus-outline"></i></span>
      </template>
      <template slot-scope="scope">
        <el-input v-model="scope.row[`test${index}`]"></el-input>
      </template>
    </el-table-column>
        </el-table>
   <ul>
      <el-input v-model="test1" placeholder="工序" @click.native="show1"></el-input>
<!--      <el-input v-model="test2" placeholder="客户" @click.native="show2"></el-input>
      <el-input v-model="test3" placeholder="关联单价" @click.native="show3"></el-input>
      <el-input v-model="test4" placeholder="电子模板" @click.native="show4"></el-input>
      <el-input v-model="test5" placeholder="货品" @click.native="show5"></el-input>
      <el-input v-model="test6" placeholder="供应商" @click.native="show6"></el-input>
      <el-input v-model="test7" placeholder="仓库" @click.native="show7"></el-input> -->
    </ul>

<technologyl-list @SelectedData="getData1"></technologyl-list>

<!--    部门选择
    <departmentTree></departmentTree> -->

    <!-- 员工选择 -->
<!--    <person @SelectedData="getData1" :defaultSelected="Data1"></person> -->

    <!-- 客户选择 -->
<!--    <customer @SelectedData="getData2"></customer>

    <billing @SelectedData="getData3"></billing>


    <product @SelectedData="getData5"></product>

    <supplier @SelectedData="getData6"></supplier>

    <warehouse @SelectedData="getData7" :defaultSelected="Data7"></warehouse> -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body>
      <el-input v-model="demo1"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
  // 导入vuex
  import {mapMutations,mapState} from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary'

  // 导入组件
  // import departmentTree from "@/components/commComponent/tree/tree_department";
  import person from "@/components/commComponent/list/list_personnel";
  import customer from "@/components/commComponent/list/list_customer";
  import billing from "@/components/commComponent/list/list_billing";
  import product from "@/components/commComponent/list/list_product";
  import supplier from "@/components/commComponent/list/list_supplier";
  import warehouse from "@/components/commComponent/list/list_warehouse";
  import technologylList from "@/components/commComponent/list/list_technology.vue";


  export default{
    name:"",
    data(){
      return{
        dialogVisible:false,
        demo1:'',
                  tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                  }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                  }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                  }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                  }],

                arr:[{
                  name:'自定义',
                  test:'',
                }],
        test1:'',
        test2:'',
        test3:'',
        test4:'',
        test5:'',
        test6:'',
        test7:'',

        Data1:[],

        Data1:[],
        Data2:[],
        Data3:-1,
        Data4:[],
        Data5:[],
        Data6:[],
        Data7:-1,

				Tabal:[
					{
						id:'1',
						name:'201'
					},
					{
						id:'2',
						name:'301'
					},
					{
						id:'3',
						name:'401'
					}
				]
      }
    },
    created() {
      // this.SHOW_DEPARTMENTBOX(false);//部门
      this.SHOW_PERSONNELBOX(false);//员工
      this.SHOW_CUSTOMERBOX(false);//客户
    },
    methods:{
      add(){

        this.arr.push({
          name:this.demo1,
          test:'',
        })
        this.dialogVisible = false
      },
      ...mapMutations([
        // 'SHOW_DEPARTMENTBOX',//控制单据选择弹框是否显示
        'SHOW_PERSONNELBOX',//部门员工
        'SHOW_CUSTOMERBOX',//控制客户选择弹框是否显示
        'SHOW_PRODUCTBOX',
        'SHOW_SUPPLIERBOX',
        'SHOW_WAREHOUSEBOX',
        'SHOW_BILLINGBOX',
        'SHOW_ELECTRONICTMEPLATEBOX',
        'SHOW_TECHNOLOGYBOX'
      ]),

      getData1(data){
        console.log(data);
      },

      getData2(data){
        console.log(data);
      },
      getData3(data){
          this.test3 =  data.name
          this.Data3 = data.id
      },
      getData4(data){
        console.log(data);
      },
      getData5(data){
        console.log(data);
      },
      getData6(data){
        console.log(data);
      },
      getData7(data){
        this.test7 = data.name
        this.Data7 = data.id
        console.log(data);
      },


      show1(){
          this.SHOW_TECHNOLOGYBOX(true)
      },
      show2(){
        this.SHOW_CUSTOMERBOX(true)
      },
      show3(){
        this.SHOW_BILLINGBOX(true)
      },
      show4(){
          this.SHOW_ELECTRONICTMEPLATEBOX(true)
      },
      show5(){
        this.SHOW_PRODUCTBOX(true)
      },
      show6(){
          this.SHOW_SUPPLIERBOX(true)
      },
      show7(){
        this.SHOW_WAREHOUSEBOX(true)
      },

      // test(){
      // let one = new FormData()
      //  for (var i = 0; i < this.Tabal.length; i++) {
      //       one.set('Tabal[' + i + '].name', this.Tabal[i].name)
      //      one.set('Tabal[' + i + '].id', this.Tabal[i].id)
      //    }
      //                 console.log(one);
      // }
    },
    components:{
      person,
      customer,
      billing,
      product,
      supplier,
      warehouse,
      technologylList
    }

  }
</script>

<style lang="less">
  .tableWarpper{
    width: 100%;
    height: 600px;
    display: flex;
    margin-top: 20px;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid black;
    ul{
      li{
        cursor: pointer;
        padding: 10px;
      }
      li:hover{
        color: @theme;
      }
    }
  }

</style>
<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
